import React, { useRef, useEffect, useState } from "react";
import DOMPurify from "dompurify";

let draftJsToHtml;
let contentConverter;

if (typeof window !== "undefined") {
  draftJsToHtml = require("draftjs-to-html");
  contentConverter = require("opasti-utility").default;
}

const arrayToString = (arr) => {
  if (!Array.isArray(arr)) return arr;

  let result = "";

  arr.forEach((current) => {
    if (typeof current === "string") {
      result += current;
    } else {
      result += String(current);
    }
  });

  return result;
};


const Notice = ({ notice, noticeDisplayTime, containerHeight, containerWidth, ...props }) => {
  const [applySmoothing, setApplySmoothing] = useState(false);
  const [scrollAmount, setScrollAmount] = useState(0);
  const containerRef = useRef(null);

  const ResetScroll = () => {
    setScrollAmount(0);
    setApplySmoothing(false);
  };

  const CheckScroll = () => {
    if (containerRef.current) {
      const { scrollHeight, clientHeight } = containerRef.current;
      console.log("scrollHeight (parent):", scrollHeight);
      console.log("clientHeight (child):", clientHeight);

      if (scrollHeight > clientHeight) {
        setApplySmoothing(true);
        let overflowAmount = (scrollHeight - clientHeight) / clientHeight;
        setScrollAmount(Math.round(overflowAmount * 110));
        // console.log("Start scroll");
      }
    }
  };

  // Check overflow and apply scroll effect
  useEffect(() => {
    ResetScroll(0);
    CheckScroll();
  }, [scrollAmount, containerRef, notice]);

  return (
    <div
      ref={containerRef}
      className={"h-full"}
      style={{
        maxHeight: containerHeight - 18, /* account for padding */
        transform: `translateY(${-scrollAmount}%)`,
        transition: `${
          applySmoothing
            ? "all " + (noticeDisplayTime / 1000 - 5) + "s ease-in"
            : ""
        }`,
        transitionDelay: `${applySmoothing ? "3s" : ""}`,
      }}
    >
      {/* Check notice format and show notice */}
      <NoticeContent
        content={notice.content}
        title={notice.title}
        scrollReset={ResetScroll}
      />
    </div>
  );
};

const NoticeContent = React.memo(({ content, title, scrollReset }) => {
  scrollReset();

  const contentStr = arrayToString(content);

  if (!contentStr || typeof contentStr !== "string") {
  console.error("Virhe: contentStr ei ole kelvollinen:", contentStr);
  return <div>Virhe ladattaessa sisältöä.</div>;
  }

  const rawJson = contentConverter.convertToRawDraftJson(contentStr);

  if (!rawJson || typeof rawJson !== "object") {
  console.error("Virhe: rawJson ei ole kelvollinen:", rawJson);
  return <div>Virhe ladattaessa sisältöä.</div>;
  }


  let html = draftJsToHtml(rawJson);

  if (!html || typeof html !== "string") {
  console.error("Virhe: HTML-muunnos epäonnistui:", html);
  return <div>Virhe ladattaessa sisältöä.</div>;
  }


  // Handle html sanitizing (for security) and renders
  if (!DOMPurify.isSupported) {
    // console.log("DOMPurify is not supported. HTML can't be sanitized.");
    return <></>;
  }

  html = DOMPurify.sanitize(html);

  return (
    <div>
      {title && <h1 className="mb-2 text-3xl font-semibold">{title}</h1>}
      <div
        className={"notice-content "}
        dangerouslySetInnerHTML={{ __html: html }}
      />
    </div>
  );
});

export default Notice;
